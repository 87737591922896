.c-btn--flow,
a.c-btn--flow {

  &:active,
  &:focus {
    box-shadow: none;
  }

  &:focus {
    color: $c-btn-flow--pressed--color;
    background-color: $c-btn-flow--pressed--bg-color;
    border-color: $c-btn-flow--pressed--border-color;
  }
}
