
/* -------------------------------------------------------------------------
 * ACCENT Color Scheme
 *
 */

// Color Scheme output

@if $cs-brand-accent--enabled {
  .cs-brand-accent {
    @include color-scheme($cs-brand-accent)
  }
}


// Color Scheme Modifiers
// -------------------------

// White modifier

@if $cs-brand-accent--enabled and $cs-brand-accent--white--enabled {
  .cs-brand-accent--white {
    @include color-scheme(s-core-map-diff($cs-brand-accent, $cs-brand-accent--white))
  }
}

// Light modifier

@if $cs-brand-accent--enabled and $cs-brand-accent--light--enabled {
  .cs-brand-accent--light {
    @include color-scheme(s-core-map-diff($cs-brand-accent, $cs-brand-accent--light))
  }
}

// Dark modifier

@if $cs-brand-accent--enabled and $cs-brand-accent--dark--enabled {
  .cs-brand-accent--dark {
    @include color-scheme(s-core-map-diff($cs-brand-accent, $cs-brand-accent--dark))
  }
}
