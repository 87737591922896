@import "c-btn__flow";

.c-btn--default,
a.c-btn--default {

  &:active,
  &:focus {
    box-shadow: none;
  }

  &:focus {
    color: $c-btn--pressed--color;
    border-color: $c-btn--pressed--border-color;
  }
}
