/* -------------------------------------------------------------------------
* Nav Variables
*
*/

$c-nav__link--color:                                $f-color__brand-primary--dark !default;
$c-nav__link--hover--color:                         $f-color__brand-primary--semi-dark !default;

$c-nav__link--list--color:                          $f-color__brand-primary--dark !default;

$c-nav__link--dropdown--font-weight--level-1:       700 !default;