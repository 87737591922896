/* -------------------------------------------------------------------------
 * BODY variables
 *
 */

// Material default properties

$m-body__properties: (
  "font-family"         : $e-document__body--font-family,
  "font-family-crop"    : $e-document__body--font-family--crop,
  "font-size"           : $e-document__body--font-size,
  "line-height"         : $e-document__body--line-height,
  "font-weight"         : 400,
) !default;


// Material Size Modifiers

$m-body__mod-sizes          : (
  "tiny" : (
    "font-family"           : $e-document__body--font-family,
    "font-family-crop"      : $e-document__body--font-family--crop,
    "font-size"             : $f-font-size--xs,
    "line-height"           : $e-document__body--line-height
  ),
  "small" : (
    "font-family"           : $e-document__body--font-family,
    "font-family-crop"      : $e-document__body--font-family--crop,
    "font-size"             : $f-font-size--s,
    "line-height"           : $e-document__body--line-height
  ),
  "medium"                  : $m-body__properties,
  "large" : (
    "font-family"           : $e-document__body--font-family,
    "font-family-crop"      : $e-document__body--font-family--crop,
    "font-size"             : $e-document__body--font-size,
    "line-height"           : $e-document__body--line-height,
    "font-weight"           : 700
  ),
  "huge": (
    "font-family"           : $e-document__body--font-family,
    "font-family-crop"      : $e-document__body--font-family--crop,
    "font-size"             : $f-font-size--l,
    "line-height"           : $e-document__body--line-height
  )
) !default;
