/* -------------------------------------------------------------------------
 * LINKS VARIABLES
 *
 */

$e-links__color:                          $f-color__brand-primary--dark !default;
$e-links__hover-color:                    $f-color__brand-primary--semi-dark !default;

$e-links__font-weight:                    400 !default;
$e-links__decoration:                     underline !default;
$e-links__hover-decoration:               none !default;
