
/* -------------------------------------------------------------------------
 * COLORS NEUTRAL
 *
 * Neutral colors are the ones without saturation
 * The values here are for sample proposes, consider add your owns
 *
 */


// Black & White

$f-color__neutral-white:                #fff !default;
$f-color__neutral-black:                #000 !default;

// Grey shades (Mid tones are missing intentionally)

$f-color__neutral-grey--ultra-light:    #f6f6f6 !default;
$f-color__neutral-grey--light:          #eeeeee !default;
$f-color__neutral-grey--semi-light:     #d3d9dd !default;
$f-color__neutral-grey:                 #92a1a9 !default;
$f-color__neutral-grey--semi-dark:      #7a7776 !default;
$f-color__neutral-grey--dark:           #333    !default;
$f-color__neutral-grey--ultra-dark:     #000    !default;

// Transparent colors

$f-color__neutral-white--transparent:   rgba($f-color__neutral-white, 0) !default;
$f-color__neutral-black--transparent:   rgba($f-color__neutral-black, 0) !default;


// Neutral color map

$f-colors-neutral: (
  "white":                              $f-color__neutral-white,
  "black":                              $f-color__neutral-black,
  "grey--ultra-light":                  $f-color__neutral-grey--ultra-light,
  "grey--light":                        $f-color__neutral-grey--light,
  "grey--semi-light":                   $f-color__neutral-grey--semi-light,
  "grey":                               $f-color__neutral-grey,
  "grey--semi-dark":                    $f-color__neutral-grey--semi-dark,
  "grey--dark":                         $f-color__neutral-grey--dark,
  "grey--ultra-dark":                   $f-color__neutral-grey--ultra-dark,
  "white--transparent":                 $f-color__neutral-white--transparent,
  "black--transparent":                 $f-color__neutral-black--transparent,
) !default;
