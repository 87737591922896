/* -------------------------------------------------------------------------
 * BUTTON
 */

// Variants > Default
// -------------------------------

// Hover
$c-btn--hover--color:                           $f-color__brand-primary--semi-dark !default;
$c-btn--hover--border-color:                    $f-color__brand-primary--semi-dark !default;

// Pressed
$c-btn--pressed--color:                         $f-color__brand-primary--dark !default;
$c-btn--pressed--border-color:                  $f-color__brand-primary--dark !default;

// Variants > Secondary
// -------------------------------

$c-btn-secondary--bg-color:                     $f-color__brand-primary !default;
$c-btn-secondary--border-color:                 $f-color__brand-primary !default;
$c-btn-secondary--shadow:                       none!default;

// Hover
$c-btn-secondary--hover--bg-color:              $f-color__brand-primary--semi-dark !default;
$c-btn-secondary--hover--border-color:          $f-color__brand-primary--semi-dark !default;

// Pressed
$c-btn-secondary--pressed--bg-color:            $f-color__brand-primary--dark !default;
$c-btn-secondary--pressed--border-color:        $f-color__brand-primary--dark !default;
$c-btn-secondary--pressed--shadow:              none !default;

// Focus
$c-btn-secondary--focus--shadow:                $f-shadow--glow $f-color__brand-primary--semi-light !default;

// Variants > Flow
// -------------------------------

$c-btn-flow--bg-color:                          $f-color__brand-accent !default;
$c-btn-flow--border-color:                      $f-color__brand-accent !default;

// Hover
$c-btn-flow--hover--bg-color:                   $f-color__brand-accent--semi-dark !default;
$c-btn-flow--hover--border-color:               $f-color__brand-accent--semi-dark !default;

// Pressed
$c-btn-flow--pressed--bg-color:                 $f-color__brand-accent--dark !default;
$c-btn-flow--pressed--border-color:             $f-color__brand-accent--dark !default;