
/* -------------------------------------------------------------------------
 * SHADOWS
 *
 * Define box and text shadows
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main shadow

$f-shadow:                    0px 2px 10px 0px !default;
$f-shadow__color:             $f-color__neutral-black !default;
$f-shadow__opacity:           0.10 !default;

// Shadow variants

$f-shadow--discrete:          $f-shadow !default;
$f-shadow--elevated:          1px 1px 5px 0px !default;
$f-shadow--text:              1px 1px 5px !default;

// Shadow opacity variants

$f-shadow__opacity--elevated: 0.12 !default;
$f-shadow__opacity--text:     0.85 !default;
