
/* -------------------------------------------------------------------------
 * COLORS BRAND
 *
 * Define brand colors and its priorities
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main brand colors

$f-color__brand-primary:                  #00c9ff !default;
$f-color__brand-secondary:                #00004A !default;
$f-color__brand-accent:                   #ff6600 !default;

// Primary shades

$f-color__brand-primary--ultra-light:     #d2f5ff !default;
$f-color__brand-primary--light:           #a5ecff !default;
$f-color__brand-primary--semi-light:      #4cd9ff !default;

$f-color__brand-primary--semi-dark:       #00a0d4 !default;
$f-color__brand-primary--dark:            #0079aa !default;

// Secondary shades

$f-color__brand-secondary--ultra-light:   #bfbfd1 !default;
$f-color__brand-secondary--light:         #7f7fa4 !default;
$f-color__brand-secondary--semi-light:    #404078 !default;

$f-color__brand-secondary--semi-dark:     #00003b !default;
$f-color__brand-secondary--dark:          #000025 !default;

// Accent shades

$f-color__brand-accent--ultra-light:      #fff3ec !default;
$f-color__brand-accent--light:            #ffe8d9 !default;
$f-color__brand-accent--semi-light:       #ffb27f !default;

$f-color__brand-accent--semi-dark:        #d64300 !default;
$f-color__brand-accent--dark:             #ae1c00 !default;

