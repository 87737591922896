
/* -------------------------------------------------------------------------
 * COLORS STATE
 *
 * Define all state colors
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main state colors

$f-color__state-success:                  #09a200 !default;
$f-color__state-info:                     #00c9ff !default;
$f-color__state-warning:                  #fff92c !default;
$f-color__state-danger:                   #ff2f9b !default;

// Success shades

$f-color__state-success--ultra-light:     #b7deb5 !default;
$f-color__state-success--light:           #7eca79 !default;
$f-color__state-success--semi-light:      #4eb948 !default;

$f-color__state-success--semi-dark:       #077500 !default;
$f-color__state-success--dark:            #045100 !default;

// Info shades

$f-color__state-info--ultra-light:        #d2f5ff !default;
$f-color__state-info--light:              #a5ecff !default;
$f-color__state-info--semi-light:         #4cd9ff !default;

$f-color__state-info--semi-dark:          #00a0d4 !default;
$f-color__state-info--dark:               #0079aa !default;

// Warning shades

$f-color__state-warning--ultra-light:     #fffeea !default;
$f-color__state-warning--light:           #feffab !default;
$f-color__state-warning--semi-light:      #feff76 !default;

$f-color__state-warning--semi-dark:       #ffed00 !default;
$f-color__state-warning--dark:            #ffdd00 !default;

// Danger shades

$f-color__state-danger--ultra-light:      #fff3f9 !default;
$f-color__state-danger--light:            #ffe0f0 !default;
$f-color__state-danger--semi-light:       #ff82c3 !default;

$f-color__state-danger--semi-dark:        #dc0072 !default;
$f-color__state-danger--dark:             #a40055 !default;
