
/* -------------------------------------------------------------------------
 * FONTS
 *
 * Font families & fallback
 * The values here are for sample proposes, consider add your owns
 *
 */

// Font imports

@import "foundations__font-families--tahoma";

// Main font families

$f-font-family__primary:          $f-font-family__tahoma, $f-font-family__tahoma--fallback !default;

$f-font-family__primary--crop:    $f-font-family__tahoma--crop !default;
$f-font-family__secondary--crop:  $f-font-family__tahoma--crop !default;

// Font families map

$f-font-families: (
  "primary":    $f-font-family__primary,
  "seconary":   $f-font-family__primary,
) !default;

$f-font-families--crops: (
  "primary":      $f-font-family__primary--crop,
  "secondary":    $f-font-family__secondary--crop,
) !default;
