/* -------------------------------------------------------------------------
 * HEADING variables
 *
 */


// Material default properties

$m-heading__properties: (
  "font-family"         : $f-font-family__primary,
  "font-family-crop"    : $f-font-family__primary--crop,
  "font-weight"         : 700,
  "font-size"           : $f-font-size--xl,
  "line-height"         : $e-headings__line-height
) !default;


// Material Size Modifiers

$m-heading__mod-sizes   : (
  "micro": (
    "font-family"       : $e-document__body--font-family,
    "font-family-crop"  : $e-document__body--font-family--crop,
    "font-weight"       : 400,
    "font-size"         : $f-font-size--s,
    "line-height"       : $e-document__body--line-height
  ),
  "tiny": (
    "font-family"       : $f-font-family__primary,
    "font-family-crop"  : $f-font-family__primary--crop,
    "font-size"         : $f-font-size--s,
    "font-weight"       : 700,
    "line-height"       : $e-headings__line-height
  ),
  "small": (
    "font-family"       : $f-font-family__primary,
    "font-family-crop"  : $f-font-family__primary--crop,
    "font-size"         : $f-font-size--m,
    "font-weight"       : 700,
    "line-height"       : $e-headings__line-height
  ),
  "medium"              : $m-heading__properties,
  "large": (
    "font-family"       : $f-font-family__primary,
    "font-family-crop"  : $f-font-family__primary--crop,
    "font-size"         : $f-font-size--xxl,
    "font-weight"       : 700,
    "line-height"       : $e-headings__line-height
  ),
  "huge": (
    "font-family"       : $f-font-family__primary,
    "font-family-crop"  : $f-font-family__primary--crop,
    "font-size"         : $f-font-size--xxxl,
    "font-weight"       : 700,
    "line-height"       : $e-headings__line-height
  )
) !default;
