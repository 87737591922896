.t-accommodation {
  .c-dropdown__swipe {
    .o-swipe-btn {
      &.o-swipe-btn--left {
        background: linear-gradient(
          to right, $t-accommodation__o-swipe-btn--bg 40%, rgba($t-accommodation__o-swipe-btn--bg, 0)
        );
      }

      &.o-swipe-btn--right {
        background: linear-gradient(
          to left, $t-accommodation__o-swipe-btn--bg 40%, rgba($t-accommodation__o-swipe-btn--bg, 0)
        );
      }
    }
  }

  .w-selected-state__basket-usps-content {
    .w-bullet-list__usps .c-bullet-list__icon {
      vertical-align: middle;
    }
  }

  @import "fr-acco-usps";
}
